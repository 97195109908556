/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import React, { ReactElement } from 'react';
import { TrackingServiceContext } from './TrackingServiceContext';

export const TrackingServiceContextProvider: React.FC<{
  trackingService: TrackingServiceV2;
  children?: ReactElement;
}> = ({ trackingService, children }) => {
  return (
    <TrackingServiceContext.Provider value={{ trackingService }}>
      {children}
    </TrackingServiceContext.Provider>
  );
};

TrackingServiceContextProvider.displayName = 'TrackingServiceContextProvider';
